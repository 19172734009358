/* Device Width--Resolution Settings */

/* Extra Small Screen (or) Phone */
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

/* Small Screen (or) Tablet */
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

/* Medium Screen (or) Desktop */
$screen-md:                  960px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

/* Large Screen (or) Wide Desktop */
$screen-lg:                  1280px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

/* So media queries don't overlap when required */
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


/* Site-wide color's settings */
$primaryColor :#202347;
$defaultButtonColor : #13BE9C;
$defaultBgColor :$primaryColor;
$topBar : $primaryColor;
$topBarColor2 :#06DFD9;
$bannerBgColor : $primaryColor;
$button-gradient-color1: $defaultButtonColor;
$button-gradient-color2: $defaultButtonColor;
$pageHeader : #088394;
$secondaryColor: #13BE9C;
$textHeaderColor : '';
$footerColor : '';
$header-text: #a1a9c3;

/* Primary Colors */
$midnight-blue: #1D2A80;
$royal-blue: #4458DA;
$solitude-light: #F1F2F6;
$brunt-orange: #FF7E2F; // header active page link color
$tickle-me-pink: #ff82a5;


/* Secondary Colors */
$dark-green: #010615;
$endeavour: #233293;
$rock-blue: #A1A9C3;
$mischka: #B2B4BA;
$solitude-dark: #DADDE6;

/* Text Colors */
$text-black: #000000;
$text-midnight-blue: #1D2A80; // searchbox header color
$text-chambray: #4C527A;
$text-mako: #41444b;
$text-rock-blue: #8D94BF;
$text-kelly-green: #5EA60F;
$text-grey-chateau: #999BA1;
$text-shuttle-grey: #6a6d78;
$text-white: #FFFFFF;
$text-mine-shaft: #333333;

$body-bg-color: #f0f2f5;
$border-bottom-color: #e8e8e8;
$error-color: #d50000;


/* Site-wide Typography */

/* Font-Family */
$font-family: Roboto, Arial, sans-serif;

/* Heading */
$HeadingL1: normal 40px $font-family;
$HeadingL2: normal 32px $font-family;
$HeadingL3: normal 18px $font-family;

/* Paragraph */
$paragraphL1: normal 18px $font-family;
$paragraphL2: normal 16px $font-family;
$paragraphL3: normal 14px $font-family;

/* Text Label */
$label: normal 12px $font-family;


/* Header */
$mobile-menu-icon-color: $secondaryColor;
$mobile-menu-drawer-color: $midnight-blue;
$mobile-menu-close-button: $text-white;
$menu-active-link: $secondaryColor;
$title-underline: #FF6767;

/* ant-card background color */
$card-secondary-color: #fbfbfb;


$blue-dark: #1D2A80;
$orange-dark: #FF6F17;
$white: #ffffff;

/* Forms */
$placeholder-text: #a1a9c3;
$input-background: #f1f2f6;

/* Page header color */
$header-color: #233293;

/* Mobile Banner background color */
$mobile-banner-color-secondary: #e4e5ec;

/* Qwiktrip Reward Program banner */
$reward-program-banner: #f1f2f6;

/* Promo-Card Background */
$promo-banner-color: #f6f6f6;


// #1D2A80 header bg color
// top bar -> #1D2A80
// nav background -> #1D2A80
// nav text actice -> #FF7E2F
// nav text -> #ffffffa6
// banner background -> #1D2A80
// search card - title - > #1d2a80
//seaction header - > #1D2A80
//section list ite, -> #1D2A80
//footer backg -> #010615

//button gradient color1 -> #ff6100
// button gradient color2 #ff7e30
