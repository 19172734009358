/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import "../../assets/styles/partials/variables";

  /* Seat Layout styles - Note: Keep it at end of the file */

  /* class to render seater type seats */
  .seater {
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 30px;
    border-radius: 2px;
    border: 1px solid $text-kelly-green;
    background-color: $text-white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }

  .step-two-city{
    min-width:200px;
  }

  .step-two-time{
    min-width:200px;
  }

  .step-two-origin{
    min-width:200px;
  }

  .step-two-destination{
    min-width:200px;
  }

  .step-two-next-day , .step-two-is-origin ,.step-two-is-destination{
    min-width:200px;
    align-items: center;
    align-content: center;
    margin-left: 20px;
  }

  .seat-number {
    font-size: 0.78em;
    text-align: center;
  }

  /* class to render sleeper type seats */
  .sleeper {
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 64px;
    border-radius: 2px;
    border: 1px solid $text-kelly-green;
    background-color: $text-white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }


  /* class to render booked seats */
  .seater.booked, .sleeper.booked {
    border: 1px solid #dfe2ed;
    background-color: #dfe2ed;
    pointer-events:none;

    .seat-line {
      background-color: #dfe2ed;
    }
  }

  /* Class to render seat gap */
  // .seatGap{
  //   width: 30px;
  //   display: flex;
  //   min-height: 36px;
  // }

  .seater.ladies, .sleeper.ladies {
    border: 1px solid $tickle-me-pink;

    > .seat-line {
      border: 1px solid $tickle-me-pink;
      background-color: $tickle-me-pink;
    }
  }


  .seater.gents, .sleeper.gents {
    border: 1px solid $midnight-blue;

    > .seat-line {
      border: 1px solid $midnight-blue;
      background-color: $midnight-blue;
    }
  }

  /* class used for seat design only. no functional */
  .seat-line {
    width: 17px;
    height: 3px;
    border-radius: 2px;
    background-color: $text-kelly-green;
    margin: 5px auto;
    align-self: flex-end;
    display: inline-block
  }

  /* container for row's with seater and sleeper class */
  .seats-container {
    display: flex;
    flex-direction: row;
  }

  .seats-column {
    // display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  
  /* Class to render seat gap */
  .seatGap{
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 30px;
    border-radius: 2px;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }
  .sleeperGap{
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 64px;
    border-radius: 2px;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }

  .layout-container {
    display: inline-flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: #f1f2f6;
    padding: 8px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .seats-column-empty {
    min-width: 30px;
  }

  .last-row {
    display: flex;
    flex-direction: row;
  }

  .sleeper-last-row {
    width: 38px;
    min-height: 25px;
    border-radius: 2px;
    border: 1px solid #dfe2ed;
    background-color: $text-white;
    margin: 2px 2px;
    display: flex;
    flex: 1;
  }

  .seat-line-last-row {
    width: 3px;
    height: 17px;
    border-radius: 2px;
    background-color: #dfe2ed;
    margin: auto 5px;
    align-self: flex-end;
    display: inline-block
  }

  /* class to display selected elected seat's */
  .selected {
    border: 1px solid $text-kelly-green;
    background-color: $text-kelly-green;
    color: $text-white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ladies.selected {
    background-color: $tickle-me-pink;
    color: $text-white;
  }

  .gents.selected {
    background-color: $midnight-blue;
    color: $text-white;
  }

  /* class to display selected elected seat's inside line */
  .selected .seat-line {
    background-color: $secondaryColor;
  }

  /* Female seat indicator */
  .female-seat .seat-line {
    background-color: $tickle-me-pink;
  }

  /* Upper and Lower deck class */
  .deck-info {
    color: #6e7282;
    font-family: $font-family;
    font-size: 10px;
    font-weight: 400;
    margin: 10px 3px;
  }

  .seat-layout-parent-container {
    display: flex;
  }
