/**
* use component name as prefix and before class name and seperate it using hypen
* Example ".mycomponent-classname"
*/

@import '../../../assets/styles/partials/variables';

.top-header{
    color: white;
    background-color: #4a4a4a;
    padding: 10px;
}

.app-name-content{
    align-items: 'center';
    justify-content: 'center';
    align-content: 'center';
}