.login-form{
  position: relative;
  background-color: #F3F3F3;
  justify-content: 'center';
  align-items: 'center';
  margin-top: 10px;
  padding:20px;
 }

 .login-form-button{
     flex:1;
     align-items: center;
     justify-content: 'center';
     align-content: 'center';
 }
