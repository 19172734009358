@import "../../assets/styles/partials/variables";

/* Desktop Ipad Pro*/
@media screen and (min-width: $screen-md-min) {
  .location-content-block {
    position:relative;
    width:600px;
    height:300px;
    padding: 5px;
  }
}

/* Ipad Tablet*/

@media screen and (min-width: $screen-sm) and (max-width: $screen-md-min) {
  .location-content-block {
    position:relative;
    width:400px;
    height:200px;
    padding: 5px;
  }
}

/*Mobile */
@media screen and (max-width: $screen-sm-min) {
  .location-content-block {
    position:relative;
    width:200px;
    height:200px;
    padding: 5px;
  }
}



.board-drop-block{
background-color: white;
padding: 10px;
}
