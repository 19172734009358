@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/base';
@import '~antd/dist/antd.css';

//ant close hide
.ant-picker-clear {    
   display: none; 
  }
.home {
    color: green;
}

.TableRowClicked {
  font-weight: bold;
}

.form{
  background-color: white;
  margin: 10px;
  padding: 10px;
}

.time-picker-to-not-display-footer {
  .ant-picker-panel-container{
    .ant-picker-panel{
      .ant-picker-footer {
        display: none;
       }
    }
  }
}

//ant input text
input[type="text"][disabled] {
  color: black;
}

//ant input number
input[disabled] {
  color: black;
}

//ant button 
button[type="button"][disabled] {
  color: black;
}

// ant select disabled
.ant-select-disabled {
  .ant-select-selector{
    .ant-select-selection-item{
      color: black;
    }
  }
}

//ant timer disabled
.ant-picker-disabled{
  .ant-picker-input{
    input{
      color: black;
    }
  }
}

.page-root{
  background-color: $solitude-light;
  flex: 1;
  @media only screen and (max-width: 768px) {
      padding: 2vw;
      max-width: 100vw;
  }
  padding: 20px;
  .title-underline{
    border-top: 4px solid $title-underline;
    width:60px;
    margin-bottom: 5px;
}
}
